// Import the core Firebase SDK and the services that you want to use
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import for Firebase Authentication

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDP8xzUU4bOvuMR2XlzhniHVNBCLv-L97Y",
  authDomain: "tradematesite.firebaseapp.com",
  projectId: "tradematesite",
  storageBucket: "tradematesite.appspot.com",
  messagingSenderId: "72074577212",
  appId: "1:72074577212:web:e13928fd2df036dbd04a39",
  measurementId: "G-7Q2FZSH5KT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics (optional, only if you're using analytics)
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and export it
const auth = getAuth(app);
export { auth, app };

// Export analytics if you need it elsewhere in your app
export { analytics };
