import React from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import Footer from "../Footer/Footer";
import "./HomePage.css";
import UploadItem from "../../Images/UploadItem.png";
import findImage from "../../Images/FindItem.png";
import offerImage from "../../Images/TradeItem.png";
import shipImage from "../../Images/ShipItem.png";

const HomePage = () => {
  return (
    <div>
      <NavigationBar />
      <div className="content">
        <div className="image-container">
          <div className="image-section">
            <img src={UploadItem} alt="Upload Item" className="display-image" />
          </div>
          <div className="image-section">
            <img src={findImage} alt="Find Item" className="display-image" />
          </div>
          <div className="image-section">
            <img src={offerImage} alt="Trade Item" className="display-image" />
          </div>
          <div className="image-section">
            <img src={shipImage} alt="Ship Item" className="display-image" />
          </div>
        </div>
        <div className="text-container">
          <div className="text-section">
            <h1>Step 1</h1>
            <h2>Upload Your Item</h2>
            <p>Start by uploading an item to your own personal portfolio.</p>
          </div>
          <div className="text-section">
            <h1>Step 2</h1>
            <h2>Search for Items</h2>
            <p>Find an item you would like to add to your own portfolio.</p>
          </div>
          <div className="text-section">
            <h1>Step 3</h1>
            <h2>Initiate Offers</h2>
            <p>Accept, counter or decline offers.</p>
          </div>
          <div className="text-section">
            <h1>Step 4</h1>
            <h2>Ship For Verification</h2>
            <p>
              Once a trade is finalized, users have 48 hours to ship the item to
              Trademate for verification.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

// const ImageSection = (props) => {
//   return (
//     <div className="image-section">
//       <img src={props.image} alt={props.name} className="display-image" />
//     </div>
//   );
// };

export default HomePage;
