import React, { useState } from "react";
import "./Uploader.css";
import NavigationBar from "../NavigationBar/NavigationBar";
import Footer from "../Footer/Footer";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { auth } from "../../firebase"; // Assuming this is your Firebase auth import
import { useAuthState } from "react-firebase-hooks/auth"; // If using react-firebase-hooks

const Uploader = () => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [description, setDescription] = useState("");
  const [user] = useAuthState(auth); // Get current user using react-firebase-hooks

  const storage = getStorage();
  const db = getFirestore();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    const reader = new FileReader();
    reader.onload = () => setPreviewUrl(reader.result);
    reader.readAsDataURL(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file || !user) return;

    const storageRef = ref(storage, `items/${file.name}`);
    const uploadTask = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(uploadTask.ref);

    const itemsRef = collection(db, "items");
    await addDoc(itemsRef, {
      imageUrl: downloadURL,
      description: description,
      uploadDate: new Date(),
      userId: user.uid
    });

    console.log("Item uploaded successfully!");
    // Reset the form or navigate to another page
    setFile(null);
    setDescription("");
    setPreviewUrl(null);
  };

  return (
    <div>
      <NavigationBar />
      <div className="upload-component">
        <form onSubmit={handleSubmit} className="upload-form">
          {previewUrl && <img src={previewUrl} alt="Preview" className="image-preview" />}
          <input
            type="text"
            placeholder="Item Description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            className="description-input"
          />
          <label className="file-input-label">
            Choose Image
            <input type="file" onChange={handleFileChange} className="file-input" />
          </label>
          <button type="submit" className="upload-button">Upload</button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Uploader;
