import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./Authentication/SignIn/SignIn";
import SignUp from "./Authentication/SignUp/SignUp";
import ProtectedRoute from "./ProtectedRoute";
import HomePage from "./components/HomePage/HomePage";
import Uploader from "./components/Uploader/Uploader";
import Portfolio from "./components/Portfolio/Portfolio";
import Trade from "./components/Trade/Trade";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/uploader" element={<Uploader />} /> 
          <Route path="/portfolio" element={<Portfolio />} /> 
          <Route path="/trade" element={<Trade />} /> 
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
