import React from "react";
import "./Footer.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Make sure Font Awesome is imported

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 It's Trademate LLC. All rights reserved.</p>
        <div className="social-icons">
          <a href="https://twitter.com/trademateapp" className="social-icon">
            <i className="fab fa-x-twitter"></i>
          </a>
          <a
            href="https://www.instagram.com/itstrademate/"
            className="social-icon"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://linkedin.com" className="social-icon">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
