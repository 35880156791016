import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import "./SignUp.css"; // Ensure this path is correct


const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const signUpWithEmail = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (error) {
      console.error("Error signing up with email", error);
    }
  };

  const signUpWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate("/");
    } catch (error) {
      console.error("Error signing up with Google", error);
    }
  };

  return (
    <div className="sign-up-container">
      <img src={`${process.env.PUBLIC_URL}/PeopleLogo1.png`} alt="People Logo" className="sign-up-logo" />
      <form onSubmit={signUpWithEmail} className="sign-up-form">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="sign-up-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="sign-up-input"
        />
        <button type="submit" className="sign-up-button">
          Sign Up with Email
        </button>
      </form>
      <button onClick={signUpWithGoogle} className="google-sign-up-button">
        Sign Up with Google
      </button>
      <Link to="/login" className="sign-up-link">
        Already have an account? Sign In
      </Link>
    </div>
  );
};

export default SignUp;
