import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { auth } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import NavigationBar from '../NavigationBar/NavigationBar';
import Footer from '../Footer/Footer';
import SearchBar from './SearchBar';
import './Trade.css';

const Trade = () => {
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [user] = useAuthState(auth);
  const [selectedImage, setSelectedImage] = useState(null);
  const db = getFirestore();

  useEffect(() => {
    const fetchItems = async () => {
      if (user) {
        const q = query(collection(db, 'items'), where('userId', '!=', user.uid));
        const querySnapshot = await getDocs(q);
        const itemsData = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(item => item.description.toLowerCase().includes(searchQuery.toLowerCase()));
        setItems(itemsData);
      }
    };

    fetchItems();
    const interval = setInterval(fetchItems, 1000);
    return () => clearInterval(interval);
  }, [db, user, searchQuery, user?.uid]);

  const handleInitiateTrade = (itemId) => {
    console.log(`Initiate trade for item ID: ${itemId}`);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const timeSince = (date) => {
    const now = new Date();
    const uploadedDate = date.toDate();
    const seconds = Math.floor((now - uploadedDate) / 1000);

    let interval = seconds / 86400;
    if (interval > 1) {
      return `${Math.floor(interval)} days ago`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return `${Math.floor(interval)} hours ago`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      return `${Math.floor(interval)} minutes ago`;
    }
    return `${seconds} seconds ago`;
  };

  return (
    <div>
      <NavigationBar />
      <div className="trade-container">
        <SearchBar setSearchQuery={setSearchQuery} />
        {items.map((item) => (
          <div key={item.id} className="trade-item">
            <img src={item.imageUrl} alt="Item" className="trade-image" onClick={() => handleImageClick(item.imageUrl)} />
            <div className="item-info">
              <p>{item.description}</p>
              <p>Uploaded {timeSince(item.uploadDate)}</p>
              <p>Uploader ID: {item.userId}</p>
              <button onClick={() => handleInitiateTrade(item.id)} className="trade-button">Trade</button>
            </div>
          </div>
        ))}
        {selectedImage && (
          <div className="image-modal" onClick={closeImageModal}>
            <img src={selectedImage} alt="Enlarged item" className="enlarged-image" />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Trade;
