import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import "./SignIn.css";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const signInWithEmail = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (error) {
      console.error("Error signing in with email", error);
      setError("Failed to sign in. Please check your credentials.");
    }
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate("/");
    } catch (error) {
      console.error("Error signing in with Google", error);
      setError("Failed to sign in with Google.");
    }
  };

  return (
    <div className="sign-in-container">
      <img
        src={`${process.env.PUBLIC_URL}/PeopleLogo1.png`}
        alt="People Logo"
        className="sign-up-logo"
      />
      {error && <div className="sign-in-error">{error}</div>}
      <form onSubmit={signInWithEmail} className="sign-in-form">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="sign-in-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="sign-in-input"
        />
        <button type="submit" className="sign-in-button">
          Sign In with Email
        </button>
      </form>
      <div className="sign-in-or">or</div> {/* "or" text separator */}
      <img
        src={`${process.env.PUBLIC_URL}/GoogleSignInButton.png`}
        alt="Sign In with Google"
        className="google-sign-in-image"
        onClick={signInWithGoogle}
      />
      {/* <Link to="/signup" className="sign-in-link">
        Don't have an account? Sign Up
      </Link> */}
    </div>
  );
};

export default SignIn;
