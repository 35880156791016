import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { auth } from '../../firebase'; // Adjust the path as needed
import { useAuthState } from 'react-firebase-hooks/auth';
import NavigationBar from '../NavigationBar/NavigationBar';
import Footer from '../Footer/Footer';
import './Portfolio.css';

const Portfolio = () => {
  const [items, setItems] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null);
  const [editDescription, setEditDescription] = useState("");
  const [user] = useAuthState(auth);
  const db = getFirestore();

  useEffect(() => {
    const fetchItems = async () => {
      if (user) {
        const q = query(collection(db, 'items'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const itemsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setItems(itemsData);
      }
    };

    fetchItems();
  }, [user, db]); // Include db in the dependency array

  const startEdit = (item) => {
    setEditingItemId(item.id);
    setEditDescription(item.description);
  };

  const stopEdit = () => {
    setEditingItemId(null);
    setEditDescription("");
  };

  const saveEdit = async () => {
    if (editingItemId) {
      const docRef = doc(db, 'items', editingItemId);
      await updateDoc(docRef, {
        description: editDescription
      });
      setItems(items.map(item => item.id === editingItemId ? { ...item, description: editDescription } : item));
      stopEdit();
    }
  };

  const handleDelete = async (itemId) => {
    const docRef = doc(db, 'items', itemId);
    await deleteDoc(docRef);
    setItems(items.filter(item => item.id !== itemId));
  };

  return (
    <div>
      <NavigationBar />
      <div className="portfolio-container">
        {items.map((item) => (
          <div key={item.id} className="portfolio-item">
            <img src={item.imageUrl} alt="Item" className="portfolio-image" />
            {editingItemId === item.id ? (
              <div>
                <input 
                  type="text" 
                  value={editDescription} 
                  onChange={(e) => setEditDescription(e.target.value)} 
                />
                <button onClick={saveEdit}>Save</button>
                <button onClick={stopEdit}>Cancel</button>
              </div>
            ) : (
              <div>
                <p className="item-description">{item.description}</p>
                <button onClick={() => startEdit(item)}>Edit</button>
              </div>
            )}
            <p className="upload-date">Uploaded on: {item.uploadDate.toDate().toDateString()}</p>
            <button onClick={() => handleDelete(item.id)} className="delete-button">Delete</button>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Portfolio;
