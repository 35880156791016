import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import "./NavigationBar.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import LogoImage from "../../Images/tradematepeoplelogo.png";

const NavigationBar = () => {
  const [user, setUser] = useState(null);
  const [isMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    if (window.confirm("Are you sure you want to sign out?")) {
      try {
        await signOut(auth);
        navigate("/login");
      } catch (error) {
        console.error("Error signing out: ", error);
      }
    }
  };

  return (
    <nav className={isMobile ? "navbar-mobile" : "navbar"}>
      <Link to="/" className="nav-logo">
        <img src={LogoImage} alt="TradeMate Logo" className="nav-logo-img" />
        <span className="nav-logo-text">Trademate</span>
      </Link>
      <div className={isMobile ? "nav-links-mobile" : "nav-links"}>
        <Link to="/uploader" className="nav-icon">
          <i className="fas fa-solid fa-upload"></i>
        </Link>
        <Link to="/portfolio" className="nav-icon">
          <i className="fas fa-solid fa-folder"></i>
        </Link>
        <Link to="/trade" className="nav-icon">
          <i className="fas fa-sync-alt"></i>
        </Link>{" "}
        {/* Adjusted for consistency */}
        <Link to="/chat" className="nav-icon">
          <i className="fas fa-solid fa-comment"></i>
        </Link>
        {user ? (
          <button onClick={handleSignOut} className="nav-icon">
            <i className="fas fa-sign-out-alt"></i>
          </button>
        ) : (
          <Link to="/login" className="nav-icon">
            <i className="fas fa-sign-in-alt"></i>
          </Link>
        )}
      </div>
    </nav>
  );
};

export default NavigationBar;
